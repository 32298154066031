import React, { useContext } from "react"
import * as styles from "components/member/Setting/paymentList.module.css"
import { payMethodTable } from "utils/data.js"
import { getDateYMD } from "utils"
import Deed from "components/payments/deed"
import modalContext from "context/modalContext"

const PaymentList = props => {
  const goods = props.goodsList.get(props.billingInfo.goods_no)

  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const onDeed = () => {
    setModalMain(
      <Deed
        close={() => {
          setmodalOpen(false)
        }}
        billing_no={props.billingInfo.billing_no}
        goodsList={props.goodsList}
      />
    )

    setmodalOpen(true)
  }

  return (
    <>
      <div className={styles.main}>
        <div className={styles.con}>
          <div>{goods?.title}</div>
          <div>
            {getDateYMD(
              props.billingInfo?.pg_paid_at === 0
                ? props.billingInfo?.registerTime
                : props.billingInfo?.pg_paid_at,
              "YYYY년MM월DD일"
            )}
          </div>
          {/* <div>{"거래번호"}</div> */}
          <div>{payMethodTable.get(props.billingInfo?.billing_method)}</div>

          {props.billingInfo?.billing_method === 99 ? (
            <div>0원</div>
          ) : (
            <div>
              <div>{(goods?.price?.toLocaleString() || "00,000") + "원"}</div>
              <div>
                {"(부가세 " +
                  ((goods?.price * 0.1)?.toLocaleString() || "00,000") +
                  "원)"}
              </div>
            </div>
          )}
          <div
            onClick={() => onDeed(setmodalOpen, setModalMain)}
            style={{ cursor: "pointer" }}
          >
            영수증 보기
          </div>
        </div>
      </div>
      <hr />
    </>
  )
}

export default PaymentList
