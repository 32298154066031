import React, { Fragment, useContext, useEffect, useState } from "react"
import Layout from "components/common/layout.js"
import SettingLayout from "components/member/Setting/settingLayout"
import * as styles from "pages/member/setting/paymentHistory.module.css"
import PaymentList from "components/member/Setting/paymentList"
import modalContext from "context/modalContext"
import api from "apis"
import { setProductListAsync } from "store/modules/payInfoReducer.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { license } from "images"
import Pagination from "components/tools/pagination.js"
import { download } from "utils"
import queryString from "query-string"

const PaymentHistoryPage = props => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const [blist, setblist] = useState([])
  const [blistPageOrigin, setblistOrigin] = useState([])
  const [scbInfo, setScbInfo] = useState({})

  const { page } = queryString.parse(props.location.search)

  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  const dispatch = useDispatch()
  const size = 7
  useEffect(() => {
    ;(async () => {
      const blist = await api.scbHistory()
      const scbInfo = await api.getscbDetail()
      if (blist) {
        setblist(blist)
        setblistOrigin(
          blist.slice((currentPage - 1) * size, currentPage * size)
        )
      }
      if (scbInfo) setScbInfo(scbInfo)
      dispatch(setProductListAsync())
    })()
  }, [currentPage])

  const { list: goodsList } = useSelector(
    ({ payInfoReducer: { product } }) => product,
    shallowEqual
  )

  // const onLisence = () => {
  //   setmodalOpen(true)
  //   setModalMain(
  //     <div
  //       style={{
  //         backgroundColor: "rgba(0, 0, 0, 0)",
  //         width: "500px",
  //       }}
  //       onClick={() => {
  //         setmodalOpen(false)
  //       }}
  //     >
  //       <img src={license} />
  //     </div>
  //   )
  // }

  const onLisencetest = () => {
    download(
      "https://peach-bookcases-dist-www.s3.ap-northeast-2.amazonaws.com/peachLicense.jpg",
      "피치마켓사업자등록증"
    )
  }

  const goods = goodsList.get(scbInfo.goods_no)

  console.log(goods)
  return (
    <Layout settingHeader payment headerStyle={{ display: "none" }}>
      <SettingLayout title="결제기록" hr="0" />

      <div className={styles.main2}>
        <div className={styles.main}>
          <div style={{ marginTop: "20px" }}>{scbInfo.name}님의 구독 정보 </div>
          <div style={{ marginTop: "20px" }}>
            <div>{goods?.title}</div>
            {/* <div>1개의 디바이스</div> */}
            <div>
              {goods?.isReg ? "매월 " : ""}
              {goods?.price?.toLocaleString()}원
            </div>
          </div>
          <div>
            <div
              className={"confirmBtn"}
              onClick={onLisencetest}
              style={{ marginTop: "74px", height: "36px", lineHeight: "36px" }}
            >
              피치마켓 사업자등록증
            </div>
          </div>
        </div>
      </div>

      <div className={styles.con}>
        <hr />
        <div className={styles.category}>
          <div>상품명</div>
          {/* <div>구독기간</div> */}
          <div>결제일</div>
          {/* <div>거래번호</div> */}
          <div>결제수단</div>
          <div>결제금액</div>
          <div>영수증</div>
        </div>
        <hr />

        <div>
          {blistPageOrigin.map((v, idx) => {
            return (
              <Fragment key={idx}>
                <PaymentList
                  billingInfo={v}
                  scbInfo={scbInfo}
                  goodsList={goodsList}
                />
              </Fragment>
            )
          })}
        </div>
      </div>
      <Pagination
        total={blist?.length}
        pagePostSize={size}
        pageSize={10}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </Layout>
  )
}

export default PaymentHistoryPage

const transcriptName = [
  // "거래번호",
  "거래일자",
  "구매자",
  "상품금액",
  "할인금액",
  "결제금액",
  "결제수단",
  "구독상품",
  "구독기간",
]
